'use strict'
import {ADD_TODO, DELETE_TODO, SELECTED_ALL_TODO} from "./mutation-types";

export default {
    addTodo({commit}, todo) {
        commit(ADD_TODO, {todo});
    },
    deleteTodo({commit}, index) {
        commit(DELETE_TODO, {index});
    },
    selectedAll({commit}, checked) {
        commit(SELECTED_ALL_TODO, {checked});
    }
}
