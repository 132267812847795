<template>
  <div id="header">
      <img class="logo" src="../assets/image/home/logo@2x.png" />
      <div class="nav" ref="nav">
        <ul>
          <li v-for="tab in tabs" :key="tab.path" :class="selectedPath === tab.path ? 'active' : ''">
            <a href="#" @click="routerTo(tab.path)">{{ tab.name }}</a>
          </li>
        </ul>
      </div>
    <div style="flex: 1"></div>
    <div class="e-right">
      <a href="http://b.ctoa.cn" target="_blank"><img class="trial" src="../assets/image/home/login@2x.png" /></a>
      <a href="https://jinshuju.net/f/AjNhBM" target="_blank"><img class="login" src="../assets/image/home/trial@2x.png" /></a>
    </div>
    <div class="dropdown">
      <img @click="menuClicked()" src="../assets/image/header/menu.png" />
      <div class="dropdown-content" v-show="displayMenu">
        <ul>
          <li v-for="tab in tabs" :key="tab.path">
            <a :class="selectedPath === tab.path ? 'active' : ''" href="#" @click="routerTo(tab.path)">{{ tab.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      tabs: [
        {name: '首页', path: 'home'},
        {name: '产品服务', path: 'service'},
        {name: '关于我们', path: 'about'},
        // {name: '无条件退款承诺', path: 'promise'},
      ],
      selectedPath: 'home',
      displayMenu: false,
    };
  },
  methods: {
    routerTo: function (path) {
      if (this.selectedPath === path) {
        return;
      }
      this.selectedPath = path;
      this.$router.push({path: path || '/'});
      this.displayMenu = false;
    },

    menuClicked: function () {
      this.displayMenu = true;
    },
  }
}
</script>

<style scoped lang="less">

@import "../assets/css/base";

* {

}

#header {
  .v-row();
  justify-content: space-between;
  align-items: center;
  padding: 0 122px;
  height: 60px;
  background-color: white;

  // Web
  @media screen and (min-width: 769px) {
    //width: 1440px;
  }


  // iPad Pro
  @media @max-1024 {
    padding: 0 20px;
  }

  // iPad
  @media @max-768 {
    padding: 0 20px;
  }

  @media @max-428 {
    //width: 100%;
    padding: 0 0 0 20px;
  }


  .logo {
    flex-shrink: 0;
    width: 92px;
    height: 32px;
  }

  .nav {
    .v-row();
    //background-color: #e64340;
    margin-left: 112px;
    justify-content: space-between;
    flex-shrink: 0;

    @media screen and (max-width: 1024px) {
      margin-left: 40px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    ul {
      list-style: none;
    }

    li {
      display: inline;
      float:left;
      margin-right: 44px;

      @media screen and (max-width: 1024px) {
        margin-right: 22px;
      }
    }

    li.active a {
      color: @color-text-title;
      font-weight: 500;
    }

    li:hover a {
      color: @color-text-secondary;
      font-weight: 500;
    }

    li a{
      .h3();
      color: @color-text-secondary;
      transition: all ease .2s;
    }

  }

  .e-right {
    .v-row();
    margin-left: 50px;
    flex-shrink: 0;

    @media @max-428 {
      display: none;
    }

    a {
      cursor: pointer;
    }

    .trial {
      width: 90px;
      height: 33px;
      margin-right: 20px;
    }

    .login {
      width: 90px;
      height: 33px;
    }
  }

  .dropdown {
    position: relative;
    right: 0;
    display: inline-block;
    width: 160px;
    //background-color: #e64340;

    @media screen and (min-width: 428px) {
      display: none;
    }

    img {
      margin-left: 97px;
      width: 23px;
      height: 23px;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    //min-width: 160px;
    box-shadow: 16px 16px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 20px;
    //margin-top: 14px;
    z-index: 999;

    ul {
      list-style: none;
    }

    li {
      display: inline;
      float:left;
      //margin-left: 44px;
      text-align: right;
      //background-color: #179b16;
      padding: 5px 0;
      width: 100%;
    }

    li a{
      .h3();
      color: @color-text-tips;
      transition: all ease .2s;
    }

    .active {
      color: @color-text-title;
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}

</style>
