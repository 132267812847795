<template>
  <div id="body">
    <img :src="require('../../assets/image/promise/banner@2x.png')">
    <div class="title">诗码科技无条件退款服务承诺</div>
    <div id="border">
      <div class="b-title">服务内容</div>
      <div class="b-text">2.1.SpaceUp为用户提供楼宇智能管理系统，实现房源管理、合同管理、租户管理、账单管理、收支管理、发票管理、审批流程、后台配置、 权限设置等功能，具体以产品开发及服务可呈现现状为准。 2.2.除非您与我们另有约定，您同意本服务仅为您及您向我们报备的关联方根据 您与我们之间约定的使用方式及使用范围使用。我们所提供的服务中可能包括第三方服务链接，您同意在使用过程中显示我们和第三方供应 商、合作伙伴提供的第三方链接。除法律法规明确规定外，您应自行对依该第三方链接进行的交易负责，对您因依该第三方链接进行的交易 或第三方链接提供的内容而遭受的损失或损害，我们免于承担任何责任。</div>
      <div class="b-title">内容和行为规范</div>
      <div class="b-text">3.1.您同意按照SpaceUp设定的功能和内容要求生产内容或其他信息（包括但不限于文字、标签、图片、音频、视频、图表、电子文件、网 页链接等），您不得利用SpaceUp服务制作、复制、发布、传播可能影响SpaceUp正常运营，以及侵犯其他用户或第三方合法权益的内容， 包括但不限于：</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Promise",
}
</script>

<style scoped lang="less">
@import "../../assets/css/base";
#body {
  width: 100%;
  .v-column();
  //justify-content: center;
  //align-items: center;
  background-color: #F6F7F9;

  .mixin-module-title {
    font-size: 34px;
    font-weight: 800;
    color: #0B2D4B;

    @media @max-768 {
      font-size: 34px * @scale-rate-768;
      font-weight: 800 * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 34px * @scale-rate-428;
      font-weight: 800 * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 34px * @scale-rate-360;
      font-weight: 800 * @scale-rate-360;
    }
  }

  img {

    width: 100%;
    height: 358px;

    position: absolute;

    @media @max-1024 {
      height: 255px;
    }

    @media @max-768 {
      height: 269px;
    }

    @media @max-428 {
      height: 116px;
    }

    @media @max-360 {
      height: 100px;
    }

    object-fit: cover;
  }

  .title {
    font-size: 29px;
    font-weight: 800;
    color: #FFFFFF;
    margin: 260px 122px 38px 122px;
    z-index: 998;
    text-align: left;

    @media @max-1024 {
      margin: 157px 92px 38px 92px;
      font-size: 29px;
    }

    @media @max-768 {
      margin: 137px 82px 38px 82px;
      font-size: 29px * @scale-rate-768;
    }

    @media @max-428 {
      margin: 67px * @scale-rate-428 30px 38px * @scale-rate-428 30px;
      font-size: 29px * @scale-rate-428;
    }

    @media @max-360 {
      margin: 67px * @scale-rate-360 30px 38px * @scale-rate-360 30px;
      font-size: 29px * @scale-rate-360;
    }
  }
}

#border {
  margin: 0 122px 64px 122px;
  padding: 50px 48px;
  z-index: 998;
  background: #FFFFFF;
  box-shadow: 0px 2px 11px 0px rgba(15, 84, 204, 0.13);

  @media @max-1024 {
    margin: 0 92px 64px 92px;
  }

  @media @max-768 {
    margin: 0 82px 64px 82px;
  }

  @media @max-428 {
    margin: 0 30px 64px * @scale-rate-428 30px;
    padding: 50px* @scale-rate-428 48px* @scale-rate-428;
  }

  @media @max-360 {
    margin: 0 30px 64px * @scale-rate-360 30px;
    padding: 50px * @scale-rate-360 48px * @scale-rate-360;
  }

  .b-title {
    text-align: left;
    color: #011529;
    font-size: 21px;
    font-weight: 500;

    @media @max-1024 {

    }

    @media @max-768 {
      font-size: 21px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 21px * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 21px * @scale-rate-360;
    }
  }

  .b-text {
    text-align: left;
    color: #5A6874;
    font-weight: 400;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 30px;

    @media @max-1024 {

    }

    @media @max-768 {
      font-size: 18px * @scale-rate-768;
      margin-top: 10px * @scale-rate-768;
      margin-bottom: 30px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 18px * @scale-rate-428;
      margin-top: 10px * @scale-rate-768;
      margin-bottom: 30px * @scale-rate-768;
    }

    @media @max-360 {
      font-size: 18px * @scale-rate-360;
      margin-top: 10px * @scale-rate-768;
      margin-bottom: 30px * @scale-rate-768;
    }
  }
}

</style>
