<template>
  <div id="app">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  /*
  @media (min-width: 1140px) {
    background-color: red;
    width: 1140px;
  }

  @media (min-width: 768px) {
    @media (min-resolution: 192dpi) {
      //background-image: url(/img/retina2x.png);
    }
  }

  // Web
  @media screen and (min-width: 769px) {

  }

  // iPad pro && iPad mini 768px
  @media screen and (max-width: 768px) and (min-width: 481px) {

  }

  // Android 6.4inch 480px
  @media screen and (max-width: 480px) and (min-width: 415px) {

  }

  // iPhone Plus 414px
  @media screen and (max-width: 414px) and (min-width: 361px) {

  }

  // Android 360px
  @media screen and (max-width: 360px) {

  }

  // iPhone 320px
  @media screen and (max-width: 320px) {

  }
   */
}

</style>
