'use strict'
import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from "@/pages/Home/Home";
import Service from "@/pages/Service/Service";
import About from "@/pages/About/About";
import Promise from "@/pages/Promise/Promise";

Vue.use(VueRouter);

export default new VueRouter({
    // 一级路由
    routes: [
        {path: '/home', component: Home},
        {path: '/service', component: Service},
        {path: '/about', component: About},
        {path: '/promise', component: Promise},
        {path: '/', redirect: '/home'},
    ],
});
