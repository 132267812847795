<template>
  <div id="body">
    <img class="banner" :src="require('../../assets/image/service/banner@2x.png')">
    <div id="introduce-1">
      <div class="title">楼宇资产管理系统</div>
      <div class="sub-title">空间云助手楼宇资产管理系统，帮您管理楼宇、房源、合同、租客、财务资产评估等核心业务，助力资产管理降本增效。</div>
      <img class="duibi" :src="require('../../assets/image/service/duibi@2x.png')">
      <div class="d-1">
        <ul>
          <li v-for="(item, index) in d1" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </li>
        </ul>
        <img :src="require('../../assets/image/service/t1@2x.png')">
      </div>
      <div class="d-2">
        <img :src="require('../../assets/image/service/t2@2x.png')">
        <ul>
          <li v-for="(item, index) in d2" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div id="introduce-2">
      <div class="title">固定资产管理系统</div>
      <div class="sub-title">覆盖固定资产、办公用品、采购、财务的全生命周期移动化、数字化管理</div>
      <div class="t-1">
        <div class="left">
          <div class="i2-title">固定资产全生命周期管理</div>
          <div class="i2-sep"></div>
          <div class="i2-text">{{ '• 从资产入库、领用、变更、维修、调拨、到报废处置，实现全流程闭环管理。\n\n • 通过为数据配置自定义字段，让业务可扩展、适应不同场景。\n\n • 支持批量导入数据，所有数据也可导出本地备份。\n\n • 支持从钉钉、企业微信同步组织架构数据，无需手动维护，实时信息对称' }}</div>
        </div>
        <img :src="require('../../assets/image/service/i2-1@2x.png')">
      </div>
      <div class="t-2">
        <img :src="require('../../assets/image/service/i2-2@2x.png')">
        <div class="right">
          <div class="i2-title">移动扫码，多种盘点方式， 全面提升盘点效率 </div>
          <div class="i2-sep"></div>
          <div class="i2-text">{{ '• 电脑端和手机端均可创建盘点单，自定义资产盘点范围。\n\n• 一键下载盘点数据到手机端或扫描枪，彻底摆脱设备间文件导入导出。\n\n• 支持多人、同时盘点，盘点结果自动合并。\n\n• 自动生成盘点报告，既快又准，从此告别手工计算。' }}</div>
        </div>
      </div>
    </div>
    <div id="contact">
      <ul>
        <li v-for="(item, index) in contact" :key="index">
          <img class="li-icon" :src="require('../../assets/image/home/' + item.image)" />
          <div class="li-title">{{ item.title }}</div>
        </li>
      </ul>
      <div class="c-text">联系我们 获取高效的服务</div>
      <a href="https://jinshuju.net/f/AjNhBM" target="_blank"><img class="c-button" src="../../assets/image/home/contact@2x.png" /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Service",
  data() {
    return {
      d1: [
        {'title': '多项目统筹管理', 'text': '覆盖商业不动产多种业态，通过列表、剖面图等形式直观展示所有项目各维度信息，既可全面掌握项目动态，又可细化管理颗粒度'},
        {'title': '合同全生命周期管理', 'text': '合同全生命周期在线管控，实现跨部门多人协作审批、智能生成收付款计划合同到期智能预警，大大减少逾期缴费、拖欠缴费等风险'},
        {'title': '收付款', 'text': '线上核销自动化，一键生成计划收入表、确认收入表，所有财务流程有 迹可循，同时大大释放重复劳动力'},
        {'title': '租客管理', 'text': '记录并统计在租用户关键信息，自动核查工商数据，智能预警企业经营风险自由为客户添加标签，增大管理租客的便利度'},
      ],
      d2: [
        {'title': '资产评估', 'text': '实时掌握出租率、计租率、计租均价、实际现金流、现金流预测、收缴率、收益，从收入和成本细化，结合全局观资源视角更好帮助客户提高项目收益'},
        {'title': '资源分析', 'text': '通过房源户型、房源类型分析、空置天数占比等数据快速盘点空置房源，找出空置黑洞，及时调整招商策略'},
        {'title': '租赁分析', 'text': '通过月出租率走势、成交走势、租金实时单价、租期结构分析、到期续租走 势分析，精准预测租客决策行为，用数据指导招商策略优化'},
        {'title': '租客分析', 'text': '通过租客行业、签约企业注册资金、签约企业注册年限等信息描绘客户画像， 更好地为客户匹配房源，同时做好谈判策略'}
      ],
      contact: [
        {image: 'c-1@2x.png', title: '免费体验'},
        {image: 'c-2@2x.png', title: '预约演示'},
        {image: 'c-3@2x.png', title: '价格咨询'},
        {image: 'c-4@2x.png', title: '联系我们'},
      ]
    };
  }
}
</script>

<style scoped lang="less">
@import "../../assets/css/base";

#body {
  width: 100%;

  .v-column();
  //justify-content: center;
  align-items: center;

  .mixin-module-title {
    font-size: 34px;
    font-weight: 800;
    color: #0B2D4B;

    @media @max-768 {
      font-size: 34px * @scale-rate-768;
      font-weight: 800 * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 34px * @scale-rate-428;
      font-weight: 800 * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 34px * @scale-rate-360;
      font-weight: 800 * @scale-rate-360;
    }
  }

  .banner {
    .v-row();
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 358px;

    @media @max-1024 {
      height: 255px;
    }

    @media @max-768 {
      height: 269px;
    }

    @media @max-428 {
      height: 116px;
    }

    @media @max-360 {
      height: 100px;
    }

    object-fit: cover;
  }
}

.title {

  @width: 30px;
  @weight: 800;

  font-size: @width;
  font-weight: @weight;
  color: #011529;

  @media @max-1024 {

  }

  @media @max-768 {
    font-size: @width * @scale-rate-768;
    font-weight: @weight * @scale-rate-768;
  }

  @media @max-428 {
    font-size: @width * @scale-rate-428;
    font-weight: @weight * @scale-rate-428;
  }

  @media @max-360 {
    font-size: @width * @scale-rate-360;
    font-weight: @weight * @scale-rate-360;
  }
}

.sub-title {
  font-size: 18px;
  color: #5A6874;
  font-weight: 400;

  //max-width: 1440px;
  margin: 16px 40px 0 40px;
  //background-color: #e64340;
  text-align: center;

  @media @max-768 {
    margin: 16px * @scale-rate-768 40px * @scale-rate-768 0 40px * @scale-rate-768;
    font-size: 18px * @scale-rate-768;
  }

  @media @max-428 {
    margin: 16px * @scale-rate-428 40px * @scale-rate-428 0 40px * @scale-rate-428;
    font-size: 18px * @scale-rate-428;
  }

  @media @max-360 {
    margin: 16px * @scale-rate-360 40px * @scale-rate-360 0 40px * @scale-rate-360;
    font-size: 18px * @scale-rate-360;
  }
}

#introduce-1 {
  width: 100%;
  padding-top: 52px;

  @media @max-768 {
    padding-top: 52px * @scale-rate-768;
  }

  @media @max-428 {
    padding-top: 52px * @scale-rate-428;
  }

  @media @max-360 {
    padding-top: 52px * @scale-rate-360;
  }


  .duibi {
    width: 755px;
    height: 480px;
    margin-top: 96px;

    @media @max-1024 {

    }

    @media @max-768 {
      width: 755px * @scale-rate-768;
      height: 480px * @scale-rate-768;
      margin-top: 96px * @scale-rate-768;
    }

    @media @max-428 {
      width: 755px * 0.45;
      height: 480px * 0.45;
      margin-top: 96px * 0.45;
    }

    @media @max-360 {
      width: 755px * 0.3;
      height: 480px * 0.3;
      margin-top: 96px * 0.3;
    }
  }

  .d-1 {
    .v-row();

    @media @max-1024 {
      .v-column();
      align-items: center;
      justify-content: center;
    }


    //justify-self: flex-start;
    justify-content: center;
    align-items: center;
    margin-top: 125px;
    //background-color: #e64340;

    @media @max-1024 {

    }

    @media @max-768 {
      margin-top: 60px;
    }

    @media @max-428 {
      margin-top: 40px;
    }

    @media @max-360 {
      margin-top: 40px;
    }

    img {
      width: 665px;
      height: 420px;
      margin-left: 47px;

      &:hover{
        //box-shadow: 1px -1px 14px 0px #1492ff;
        //box-shadow: 2px 4px 6px #1492ff;

        transform:scale(1.05, 1.05);
        -ms-transform:scale(1.05, 1.05);
        -webkit-transform:scale(1.05, 1.05);
        transition: all ease .5s;
      }

      @media @max-1024 {
        margin-left: 0;
        margin-top: 40px;
      }


      @media @max-768 {
        margin-left: 0;
        margin-top: 40px;
        width: 665px * @scale-rate-768;
        height: 420px * @scale-rate-768;
      }

      @media @max-428 {
        margin-left: 0;
        margin-top: 20px;
        width: 665px * 0.45;
        height: 420px * 0.45;
      }

      @media @max-360 {
        margin-left: 0;
        margin-top: 20px;
        width: 665px * 0.4;
        height: 420px * 0.4;
      }
    }
  }

  .d-2 {
    .v-row();

    @media @max-1024 {
      .v-column();
    }

    justify-content: center;
    align-items: center;

    //background-color: #e64340;
    margin-top: 128px;
    padding-bottom: 80px;

    @media @max-1024 {
      margin-top: 60px;
      padding-bottom: 40px;
      flex-flow: column-reverse;
    }

    img {
      width: 665px;
      height: 420px;
      margin-right: 47px;

      &:hover{
        transform:scale(1.05, 1.05);
        -ms-transform:scale(1.05, 1.05);
        -webkit-transform:scale(1.05, 1.05);
        transition: all ease .5s;
      }

      @media @max-1024 {
        margin-right: 0;
        margin-top: 40px;
      }

      @media @max-768 {
        margin-right: 0;
        margin-top: 30px;
        width: 665px * @scale-rate-768;
        height: 420px * @scale-rate-768;
      }

      @media @max-428 {
        margin-right: 0;
        margin-top: 20px;
        width: 665px * 0.45;
        height: 420px * 0.45;
      }

      @media @max-360 {
        margin-right: 0;
        margin-top: 20px;
        width: 665px * 0.4;
        height: 420px * 0.4;
      }
    }

  }

  ul {

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #0B2D4B;
      text-align: left;

      @media @max-768 {
        font-size: 20px * @scale-rate-768;
      }

      @media @max-428 {
        font-size: 20px * @scale-rate-428;
      }

      @media @max-360 {
        font-size: 20px * @scale-rate-360;
      }
    }

    @media @max-1024 {
      //background-color: #e64340;
      width: 65%;
    }

    @media @max-768 {
      width: 70%;
    }

    @media @max-428 {
      width: 80%;
    }

    @media @max-360 {
      width: 80%;
    }


    .text {
      font-size: 15px;
      color: #5A6874;
      text-align: left;
      margin-top: 6px;
      font-weight: 400;
      line-height: 26px;

      @media @max-768 {
        font-size: 15px * @scale-rate-768;
        line-height: 26px * @scale-rate-768;
      }

      @media @max-428 {
        font-size: 15px * @scale-rate-428;
        line-height: 26px * @scale-rate-428;
      }

      @media @max-360 {
        font-size: 15px * @scale-rate-360;
        line-height: 26px * @scale-rate-360;
      }
    }

    li {
      .v-column();
      width: 463px;
      //background-color: #179b16;
      margin-bottom: 40px;

      @media @max-1024 {
        //width: 70%;
        width: 100%;
        margin-bottom: 40px;
      }

      @media @max-768 {
        margin-bottom: 30px;
      }

      @media @max-428 {
        margin-bottom: 20px;
      }

      @media @max-360 {
        margin-bottom: 20px;
      }

    }

    li:last-child {
      margin-bottom: 0;
    }
  }
}

#introduce-2 {
  width: 100%;
  background-color: #F8FBFF;
  padding-top: 49px;

  @media @max-1024 {

  }

  @media @max-768 {
    padding-top: 49px * @scale-rate-768;
  }

  @media @max-428 {
    padding-top: 49px * @scale-rate-428;
  }

  @media @max-360 {
    padding-top: 49px * @scale-rate-360;
  }


  .i2-title {
    color: #011529;
    font-size: 25px;
    text-align: left;

    @media @max-1024 {

    }

    @media @max-768 {
      font-size: 25px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 22px * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 22px * @scale-rate-360;
    }
  }

  .i2-sep {
    width: 52px;
    height: 3px;
    background-color: #2394FB;
    margin: 20px 0 50px 0;

    @media @max-1024 {

    }

    @media @max-768 {
      margin: 20px * @scale-rate-768 0 50px * @scale-rate-768 0;
    }

    @media @max-428 {
      margin: 20px * @scale-rate-428 0 50px * @scale-rate-428 0;
    }

    @media @max-360 {
      margin: 20px * @scale-rate-360 0 50px * @scale-rate-360 0;
    }
  }

  .i2-text {
    color: #8D97A2;
    font-size: 17px;
    white-space: pre-wrap;
    text-align: left;

    @media @max-1024 {

    }

    @media @max-768 {
      font-size: 17px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 17px * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 17px * @scale-rate-360;
    }
  }

  .t-1 {
    .v-row();
    justify-content: center;
    align-items: center;
    margin-top: 53px;

    @media @max-1024 {

    }

    @media @max-768 {
      margin-top: 53px * @scale-rate-768;
    }

    @media @max-428 {
      margin-top: 53px * @scale-rate-428;
      .v-column();
    }

    @media @max-360 {
      margin-top: 53px * @scale-rate-360;
    }

    img {
      width: 509px;
      height: 373px;
      margin-left: 107px;

      @media @max-1024 {
        width: 509px * 0.85;
        height: 373px * 0.85;
        margin-left: 0;
      }

      @media @max-768 {
        width: 509px * 0.85;
        height: 373px * 0.85;
        margin-left: 0;
      }

      @media @max-428 {
        width: 509px * @scale-rate-428;
        height: 373px * @scale-rate-428;
        margin-left: 0;
        margin-top: 20px;
      }

      @media @max-360 {
        width: 509px * @scale-rate-360;
        height: 373px * @scale-rate-360;
        margin-left: 0;
        margin-top: 20px;
      }
    }

    .left {
      width: 600px;
      //background-color: #e64340;
      @media @max-1024 {
        margin-left: 40px;
      }

      @media @max-768 {
        margin-left: 40px;
      }

      @media @max-428 {
        width: 100%;
        margin-left: 0;
        padding: 0 20px;
      }
    }

    &:hover{

      .i2-title {
        color: @color-text-title;
        transition: all ease .5s;
      }

      .i2-sep {
        width: 30%;
        transition: all ease .5s;
      }

      .i2-text {
        color: @color-text-secondary;
        transition: all ease .5s;
      }

      img {
        transform:scale(1.05, 1.05);
        -ms-transform:scale(1.05, 1.05);
        -webkit-transform:scale(1.05, 1.05);
        transition: all ease .5s;
      }
    }
  }

  .t-2 {

    .v-row();
    justify-content: center;
    align-items: center;
    margin-top: 87px;
    padding-bottom: 119px;
    //background-color: #e64340;

    @media @max-1024 {

    }

    @media @max-768 {
      margin-top: 87px * @scale-rate-768;
      padding-bottom: 119px * @scale-rate-768;
    }

    @media @max-428 {
      .v-column();
      flex-flow: column-reverse;
      margin-top: 87px * @scale-rate-428;
      padding-bottom: 60px * @scale-rate-768;
    }

    @media @max-360 {
      margin-top: 87px * @scale-rate-360;
      padding-bottom: 60px * @scale-rate-768;
    }


    img {
      width: 473px;
      height: 453px;
      margin-right: 198px;

      @media @max-1024 {
        margin-right: 0;
      }

      @media @max-768 {
        width: 473px * 0.85;
        height: 453px * 0.85;
        margin-right: 0;
      }

      @media @max-428 {
        width: 473px * @scale-rate-428;
        height: 453px * @scale-rate-768;
        margin-right: 0;
        margin-top: 20px;
      }

      @media @max-360 {
        width: 473px * @scale-rate-360;
        height: 453px * @scale-rate-768;
        margin-right: 0;
        margin-top: 20px;
      }
    }

    .right {

      //background-color: #e64340;
      @media @max-1024 {
        margin-left: 10px;
        margin-right: 40px;
      }

      @media @max-768 {
        margin-left: 10px;
        margin-right: 40px;
      }

      @media @max-428 {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 0 20px;
      }
    }

    &:hover{

      .i2-title {
        color: @color-text-title;
        transition: all ease .5s;
      }


      .i2-sep {
        width: 30%;
        transition: all ease .5s;
      }

      .i2-text {
        color: @color-text-secondary;
        transition: all ease .5s;
      }

      img {
        transform:scale(1.05, 1.05);
        -ms-transform:scale(1.05, 1.05);
        -webkit-transform:scale(1.05, 1.05);
        transition: all ease .5s;
      }
    }
  }
}

#contact {
  .v-column();
  width: 100%;
  background-color: #2394FB;
  justify-content: center;
  align-items: center;

  ul {
    .v-row();
    //background-color: #e64340;
    //max-width: 1440px;
    width: 100%;
    //background-color: #e64340;
    padding: 30px 20px 10px 20px;
    justify-content: center;
    align-items: center;

    @media @max-768 {
      padding: 30px * @scale-rate-768 20px * @scale-rate-768 10px * @scale-rate-768 20px * @scale-rate-768;
    }

    @media @max-428 {
      //padding: 30px 20px 10px 20px;
      padding: 30px * @scale-rate-428 20px * @scale-rate-428 10px * @scale-rate-428 20px * @scale-rate-428;
    }

    // Android 360px
    @media @max-360 {
      //padding: 30px 20px 10px 20px;
      padding: 30px * @scale-rate-360 20px * @scale-rate-360 10px * @scale-rate-360 20px * @scale-rate-360;
    }

    li {
      .v-column();

      @width: 300px;
      flex: 1;
      @height: 56px + 17px + 20px;
      max-width: @width;
      min-height: @height;
      padding: 40px 0px;
      align-items: center;
      //background-color: #5a6875;

      @media @max-768 {
        max-width: @width * @scale-rate-768;
        min-height: @height * @scale-rate-768;
        padding: 40px * @scale-rate-768 0px * @scale-rate-768;
      }

      @media @max-428 {
        max-width: @width * @scale-rate-428;
        min-height: @height * @scale-rate-428;
        padding: 40px * @scale-rate-428 0px * @scale-rate-428;
      }

      // Android 360px
      @media @max-360 {
        max-width: @width * @scale-rate-360;
        min-height: @height * @scale-rate-360;
        padding: 40px * @scale-rate-360 0px * @scale-rate-360;
      }

      .li-icon {
        width: 52px;
        height: 56px;

        @media @max-768 {
          width: 52px * @scale-rate-768;
          height: 56px * @scale-rate-768;
        }

        @media @max-428 {
          width: 52px * @scale-rate-428;
          height: 56px * @scale-rate-428;
        }

        @media @max-360 {
          width: 52px * @scale-rate-360;
          height: 56px * @scale-rate-360;
        }
      }

      .li-title {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 800;
        color: #FFFFFF;

        @media @max-768 {
          margin-top: 16px * @scale-rate-768;
          font-size: 20px * @scale-rate-768;
          font-weight: 800 * @scale-rate-768;
        }

        @media @max-428 {
          margin-top: 16px * @scale-rate-428;
          font-size: 20px * @scale-rate-428;
          font-weight: 800 * @scale-rate-428;
        }

        @media @max-360 {
          margin-top: 16px * @scale-rate-360;
          font-size: 20px * @scale-rate-360;
          font-weight: 800 * @scale-rate-360;
        }
      }
    }
  }

  .c-text {
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;

    @media @max-768 {
      font-size: 24px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 24px * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 24px * @scale-rate-360;
    }
  }

  a {
    cursor: pointer;
  }

  .c-button {
    @top: 30px;
    @bottom: 42px;
    @width: 168px;
    @height: 51px;

    margin-top: @top;
    margin-bottom: @bottom;
    width: @width;
    height: @height;

    @media @max-768 {
      margin-top: @top * @scale-rate-768;
      margin-bottom: @bottom * @scale-rate-768;
      width: @width * @scale-rate-768;
      height: @height * @scale-rate-768;
    }

    @media @max-428 {
      margin-top: @top * @scale-rate-428;
      margin-bottom: @bottom * @scale-rate-428;
      width: @width * @scale-rate-428;
      height: @height * @scale-rate-428;
    }

    @media @max-360 {
      margin-top: @top * @scale-rate-360;
      margin-bottom: @bottom * @scale-rate-360;
      width: @width * @scale-rate-360;
      height: @height * @scale-rate-360;
    }
  }
}

</style>
