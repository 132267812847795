'use strict'
import {ADD_TODO, DELETE_TODO, SELECTED_ALL_TODO} from "./mutation-types";

export default {
    [ADD_TODO](state, {todo}) {
        state.todos.unshift(todo);
    },

    [DELETE_TODO](state, {index}) {
        state.todos.splice(index, 1);
    },

    [SELECTED_ALL_TODO](state, {checked}) {
        // state.todos.splice(index, 1);
        state.todos.forEach((item) => {
            item.finished = checked;
        });
    },
}
