<template>
  <div id="body">
    <img class="banner" :src="require('../../assets/image/about/banner@2x.png')">
    <div class="title">认识我们</div>
    <div class="text">空间云助手是诗码科技基于不同商办项目管理需求，研发的楼宇资产管理系统平台。助力于产业园区、写字楼、商业体的全面数字化升级，包含楼宇资产管理系统、固定资产管理系统。</div>
    <div class="title">我们努力践行的企业文化</div>
    <div class="list">
      <div class="item">
        <img class="banner" :src="require('../../assets/image/about/a-1@2x.png')">
        <div class="i-title">{{ '使命\n助力每一栋楼宇管理降本增效' }}</div>
      </div>
      <div class="item">
        <img class="banner" :src="require('../../assets/image/about/a-2@2x.png')">
        <div class="i-title">{{ '愿景\n成为商办科技服务的排头兵/值得信赖 的合伙人' }}</div>
      </div>
      <div class="item">
        <img class="banner" :src="require('../../assets/image/about/a-3@2x.png')">
        <div class="i-title">{{ '目标\n数字化重塑楼宇运营管理，让招商更容易/运营更高效/服务更标准/租户更满意/楼宇更智能' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
}
</script>

<style scoped lang="less">
@import "../../assets/css/base";

#body {
  width: 100%;

  .v-column();
  //justify-content: center;
  align-items: center;

  .mixin-module-title {
    font-size: 34px;
    font-weight: 800;
    color: #0B2D4B;

    @media @max-768 {
      font-size: 34px * @scale-rate-768;
      font-weight: 800 * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 34px * @scale-rate-428;
      font-weight: 800 * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 34px * @scale-rate-360;
      font-weight: 800 * @scale-rate-360;
    }
  }

  .banner {
    .v-row();
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 358px;

    @media @max-1024 {
      height: 255px;
    }

    @media @max-768 {
      height: 269px;
    }

    @media @max-428 {
      height: 116px;
    }

    @media @max-360 {
      height: 100px;
    }

    object-fit: cover;
  }

  .title {
    font-size: 30px;
    font-weight: 500;
    color: #011529;
    margin-top: 71px;

    @media @max-1024 {

    }

    @media @max-768 {
      font-size: 30px * @scale-rate-768;
      margin-top: 71px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 30px * @scale-rate-428;
      margin-top: 40px * @scale-rate-768;
    }

    @media @max-360 {
      font-size: 30px * @scale-rate-360;
      margin-top: 40px * @scale-rate-768;
    }
  }

  .text {
    font-size: 20px;
    font-weight: 400;
    color: #5A6874;
    line-height: 36px;
    max-width: 1165px;
    padding: 0 20px;
    margin-top: 54px;
    margin-bottom: 28px;

    @media @max-1024 {

    }

    @media @max-768 {
      font-size: 20px * @scale-rate-768;
      line-height: 36px * @scale-rate-768;
      margin-top: 54px * @scale-rate-768;
      margin-bottom: 28px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 20px * @scale-rate-428;
      line-height: 36px * @scale-rate-768;
      margin-top: 30px * @scale-rate-768;
      margin-bottom: 20px * @scale-rate-768;
    }

    @media @max-360 {
      font-size: 20px * @scale-rate-360;
      line-height: 36px * @scale-rate-768;
      margin-top: 30px * @scale-rate-768;
      margin-bottom: 20px * @scale-rate-768;
    }
  }

  .list {

    .v-row();
    margin-top: 62px;
    margin-bottom: 85px;

    @media @max-1024 {
      margin-top: 62px * @scale-rate-1024;
      margin-bottom: 85px * @scale-rate-1024;
    }

    @media @max-768 {
      margin-top: 62px * @scale-rate-768;
      margin-bottom: 85px * @scale-rate-768;
    }

    @media @max-428 {
      .v-column();
      margin-top: 30px;
      margin-bottom: 40px;
    }

    @media @max-360 {
      .v-column();
      margin-top: 30px;
      margin-bottom: 40px;
    }


    .item {

      &:hover{
        //box-shadow: 6px -1px 16px 0px #E6E9EE;
        //box-shadow: 6px 0px 10px 0px #1492ff;
        //box-shadow: 2px 4px 6px #1492ff;
        //position: relative;
        //left: 30px;
        //top: -10px;
        transform:scale(1.05, 1.05);
        -ms-transform:scale(1.05, 1.05);
        -webkit-transform:scale(1.05, 1.05);
        transition: all ease .5s;
      }

      margin: 0 72px;

      @media @max-1024 {
        margin: 0 30px;
      }

      @media @max-768 {
        margin: 0 5px;
      }

      @media @max-428 {
        margin: 0;
      }

      @media @max-360 {
        margin: 0;
      }

      img {
        width: 303px;
        height: 330px;

        @media @max-1024 {
          width: 303px * @scale-rate-1024;
          height: 330px * @scale-rate-1024;
        }

        @media @max-768 {
          width: 303px * @scale-rate-768;
          height: 330px * @scale-rate-768;
        }

        @media @max-428 {
          width: 303px * 0.9;
          height: 330px * 0.9;
        }

        @media @max-360 {
          width: 303px * 0.85;
          height: 330px * 0.85;
        }
      }

      .i-title {
        margin-top: 28px;
        width: 303px;
        white-space: pre-wrap;
        text-align: left;

        font-size: 18px;
        font-weight: 400;
        color: #011529;
        line-height: 36px;

        @media @max-1024 {
          width: 303px * @scale-rate-1024;
          font-size: 18px;
        }

        @media @max-768 {
          width: 303px * @scale-rate-768;
          font-size: 18px * @scale-rate-768;
        }

        @media @max-428 {
          width: 303px * 0.9;
          margin-top: 10px;
          margin-bottom: 30px;
          font-size: 20px * @scale-rate-428;
          line-height: 28px;
        }

        @media @max-360 {
          width: 303px * 0.85;
          margin-top: 10px;
          margin-bottom: 20px;
          font-size: 20px * @scale-rate-360;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
