<template>
  <div id="body">
    <div class="banner">
      <div class="title">空间云助手</div>
      <div class="sub-title">楼宇数字化管理好帮手，高效解决90%管楼难题</div>
      <vue-typer class="text" text='收益可视化  |  财务核销自动化  |  资产管理更便捷  |  合同到期更直观  |  账单催缴提醒更轻松'></vue-typer>
      <a @click="routerTo('/service')"><img class="btn" :src="require('../../assets/image/home/more@2x.png')" /></a>
    </div>
    <div id="introduce">
      <div class="mixin-module-title title">核心功能介绍</div>
      <ul>
        <li v-for="(item, index) in introduces" :key="index">
          <img class="li-icon" :src="require('../../assets/image/home/' + item.image)" />
          <div class="li-title">{{ item.title }}</div>
          <div class="li-text">{{ item.text }}</div>
        </li>
      </ul>
    </div>
    <div id="feature">
      <div class="mixin-module-title title">产品特色</div>
      <ul>
        <li v-for="(item, index) in features" :key="index">
          <div class="left">
            <div class="li-title">{{ item.title }}</div>
            <div class="li-text">{{ item.text }}</div>
          </div>
          <div class="right">
            <img class="li-icon" :src="require('../../assets/image/home/' + item.image)" />
          </div>
        </li>
      </ul>
    </div>
    <div id="deploy">
      <div class="mixin-module-title title">部署方式</div>
      <ul>
        <li v-for="(item, index) in deploys" :key="index">
          <img class="li-icon" src="../../assets/image/home/cloud@2x.png" />
          <div class="li-title">{{ item.title }}</div>
          <div class="li-text">{{ item.text }}</div>
        </li>
      </ul>
    </div>
    <div id="contact">
      <ul>
        <li v-for="(item, index) in contact" :key="index">
          <img class="li-icon" :src="require('../../assets/image/home/' + item.image)" />
          <div class="li-title">{{ item.title }}</div>
        </li>
      </ul>
      <div class="c-text">联系我们 获取高效的服务</div>
      <a href="https://jinshuju.net/f/AjNhBM" target="_blank"><img class="c-button" src="../../assets/image/home/contact@2x.png" /></a>
    </div>
  </div>
</template>

<script>
import { VueTyper } from 'vue-typer'
export default {
  name: "Home",
  data() {
    return {
      obj: {
        output: '',
        isEnd: false,
        speed: 80,
        singleBack: false,
        sleep: 0,
        type: 'normal',
        backSpeed: 40,
        sentencePause: false
      },
      search: '',
      active: 0,
      introduces: [
        {image: '1@2x.png', title: '楼宇管理', text: '智能以二维剖面图形式展示单一楼宇每一个房源当前状态、户型分布、出租面积、租客名称、租赁周期等信息，帮助运营方实现单一楼宇精细化管理'},
        {image: '2@2x.png', title: '合同管理', text: '合同在线签约管控，智能生成收付款计划，实现资管闭环，合同到期预警，监控图清晰暂时不同合同状态，智能提醒到期合同，避免拖租、欠租风险'},
        {image: '3@2x.png', title: '收付款', text: '自动生成收入计划表，结合实际工作场景，自动计算摊销数额，自动生成各期应收租金账单和保证金等数据，实现合同签订-租金收取-现金流测算闭环'},
        {image: '4@2x.png', title: '运营数据', text: '资产评估、资源分析、租户分析，实时掌握出租率、计租率、计租均价、现金流预测、收益等，绘制运营走势趋势图，用数据支撑招商策略优化'},
        {image: '5@2x.png', title: '固定资产', text: '从入库到报废处置，实现固定资产、耗材全流程闭环管理，提升盘点效率，公共资源在线预订，提高公共资源使用率，动态生成多维的盘点报告'},
        {image: '6@2x.png', title: '强化品牌形象', text: '以楼宇运营商和入驻企业视角，用系统工具规范融合日常运营管理，告别空间运营方式过度分散，无标准化的运转方式，建立自己的品牌形象'},
      ],
      features: [
        {image: 'f-1@2x.png', title: '运营看板', text: '• 瞬息之间就能全面、精准了解当前实时运行状态\n• 迅速注意到预警事项，有助高效做出决策\n• 租户360°画像，洞察企业发展趋势、产业前景'},
        {image: 'f-2@2x.png', title: '一件出账', text: '• 以“账单”为核心，支持水电费录入、临时费用添加\n• 一键出账，将线上账单推送给租户\n• 根据应实收账款自动进行欠费提醒'},
        {image: 'f-3@2x.png', title: '简洁易用', text: '• 管理系统 + APP + 小程序全平台覆盖，使用便捷\n• 简洁易用的界面，专业的功能设计\n• 持续的系统更新，功能日益强大，灵活易用'},
        {image: 'f-4@2x.png', title: '服务保障', text: '• 成本更低，仅对特定的功能服务付费\n• 无需维护，维护由服务提供商提供\n• 专业支持，专职服务团队全程陪伴'},
      ],
      deploys: [
        {title: '公有云', text: '成本更低，无需购买硬件或软件，仅对使用的服务付费。 无需维护，维护由服务提供商提供，近乎无限制的缩放 性提供按需源，可满足业务需求'},
        {title: '混合云', text: '混合云通常被认为是“两全其美”，它将本地基础架构或私有云与公有云相结合，组织可利用这两者的优势。'},
        {title: '私有云', text: '私有云由专供一个企业或组织使用的云计算资源构成。 私有云可在物理上位于组织的现场数据中心'},
      ],
      contact: [
        {image: 'c-1@2x.png', title: '免费体验'},
        {image: 'c-2@2x.png', title: '预约演示'},
        {image: 'c-3@2x.png', title: '价格咨询'},
        {image: 'c-4@2x.png', title: '联系我们'},
      ]
    };
  },
  components: {
    VueTyper,
  },
  methods: {
    routerTo: function (path) {
      this.$router.push({path: path || '/'});
    },
  },
}
</script>

<style scoped lang="less">
@import "../../assets/css/typer.css";
@import "../../assets/css/base";

#body {
  width: 100%;

  .mixin-module-title {
    font-size: 34px;
    font-weight: 800;
    color: #0B2D4B;

    @media @max-768 {
      font-size: 34px * @scale-rate-768;
      font-weight: 800 * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 34px * @scale-rate-428;
      font-weight: 800 * @scale-rate-428;
    }


    @media @max-360 {
      font-size: 34px * @scale-rate-360;
      font-weight: 800 * @scale-rate-360;
    }
  }

  .banner {

    .v-column();

    padding: 70px 122px;

    width: 100%;
    height: 510px;

    justify-content: flex-start;
    align-items: flex-start;

    // iPad 宽度768px以下的设备
    @media @max-768 {
      padding: 60px 60px;
      height: 300px;
    }

    @media @max-428 {
      padding: 20px 20px;
      height: 250px;
    }

    @media @max-360 {
      padding: 20px 20px;
      height: 200px;
    }

    @normal: '../../assets/image/home/banner.png';
    @retina: '../../assets/image/home/banner@2x.png';

    background-size: cover;
    background-image: url(@normal);

    @media only screen and (-webkit-min-device-pixel-ratio:1.5){
      /* 高清显示屏(设备像素比例大于等于1.5)使用2倍图  */
      background-image: url(@retina);
    }

    .title {
      font-size: 76px;
      font-weight: 800;
      color: #FFFFFF;
      //background-color: #e64340;

      @media @max-768 {
        font-size: 36px;
        font-weight: 400;
      }

      @media @max-428 {
        font-size: 32px;
        font-weight: 400;
      }

      @media @max-360 {
        font-size: 32px;
        font-weight: 400;
      }
    }

    .sub-title {
      text-align: left;

      margin-top: 35px;
      font-size: 32px;
      font-weight: bold;
      color: #9BFFC8;

      @media @max-768 {
        margin-top: 6px;
        font-size: 14px;
      }

      @media @max-428 {
        margin-top: 6px;
        font-size: 12px;
      }

      @media @max-360 {
        margin-top: 6px;
        font-size: 12px;
      }
    }

    .text {
      text-align: left;
      color: #FFFFFF;
      margin-top: 12px;
      font-size: 18px;
      line-height: 32px;
      font-weight: 400;
      max-width: 616px;
      min-height: 64px;

      @media @max-768 {
        margin-top: 6px;
        font-size: 12px;
        line-height: 20px;
        max-width: 360px;
        min-height: 40px;
      }

      @media @max-428 {
        margin-top: 6px;
        font-size: 10px;
        line-height: 20px;
        min-height: 40px;
      }

      @media @max-360 {
        margin-top: 6px;
        font-size: 10px;
        line-height: 20px;
        min-height: 40px;
      }
    }

    a {
      cursor: pointer;
      margin-top: 28px;

      @media @max-768 {
        margin-top: 16px;
      }

      @media @max-428 {
        margin-top: 12px;
      }

      @media @max-360 {
        margin-top: 12px;
      }
    }

    .btn {
      @width: 143px;
      @height: 43px;
      width: @width;
      height: @height;

      @media @max-768 {
        width: @width * @scale-rate-768;
        height: @height * @scale-rate-768;
      }

      @media @max-428 {
        width: @width * @scale-rate-428;
        height: @height * @scale-rate-428;
      }

      @media @max-360 {
        width: @width * @scale-rate-360;
        height: @height * @scale-rate-360;
      }
    }
  }
}

#introduce {

  .v-column();
  width: 100%;
  background-color: white;
  justify-content: center;
  align-items: center;

  .title {
    margin-top: 68px;
    margin-bottom: 21px;

    @media @max-768 {
      margin-top: 68px * @scale-rate-768;
      margin-bottom: 21px * @scale-rate-768;
    }

    @media @max-428 {
      margin-top: 68px * @scale-rate-428;
      margin-bottom: 21px * @scale-rate-428;
    }

    @media @max-360 {
      margin-top: 68px * @scale-rate-360;
      margin-bottom: 21px * @scale-rate-360;
    }
  }

  ul {
    .v-row();
    max-width: 1440px;
    padding: 0 0 47px 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    li {
      .v-column();
      justify-content: center;
      align-items: center;
      width: 370px;
      height: 430px;
      margin: 52px 21px 0 21px;
      box-shadow: 1px -1px 14px 0px rgba(230, 233, 238, 0.84);
      border-radius: 2px;
      background-color: white;
      //background-color: #e64340;
      //background-size: 200px 200px;

      &:hover{
        box-shadow: 1px -1px 14px 0px #1492ff;
        //box-shadow: 2px 4px 6px #1492ff;
        //position: relative;
        //left: 30px;
        //top: -10px;

        transform:scale(1.05, 1.05);
        -ms-transform:scale(1.05, 1.05);
        -webkit-transform:scale(1.05, 1.05);
        transition: all ease .5s;

      }


      @media @max-768 {
        width: 370px * @scale-rate-768;
        height: 430px * @scale-rate-768;
        margin: 52px * @scale-rate-768 21px 0 21px;
      }

      @media @max-428 {
        width: 370px * @scale-rate-428;
        height: 430px * @scale-rate-428;
        margin: 52px * @scale-rate-428 21px 0 21px;
      }

      @media @max-360 {
        width: 370px * @scale-rate-360;
        height: 430px * @scale-rate-360;
        margin: 52px * @scale-rate-360 21px 0 21px;
      }

      .li-icon {
        width: 211px;
        height: 170px;

        @media @max-768 {
          width: 211px * @scale-rate-768;
          height: 170px * @scale-rate-768;
        }

        @media @max-428 {
          width: 211px * @scale-rate-428;
          height: 170px * @scale-rate-428;
        }

        @media @max-360 {
          width: 211px * @scale-rate-360;
          height: 170px * @scale-rate-360;
        }
      }

      .li-title {
        margin-top: 23px;
        font-size: 24px;
        font-weight: 800;
        color: #0B2D4B;

        @media @max-768 {
          margin-top: 23px * @scale-rate-768;
          font-size: 24px * @scale-rate-768;
          font-weight: 800 * @scale-rate-768;
        }

        @media @max-428 {
          margin-top: 23px * @scale-rate-428;
          font-size: 24px * @scale-rate-428;
          font-weight: 800 * @scale-rate-428;
        }

        @media @max-360 {
          margin-top: 23px * @scale-rate-360;
          font-size: 24px * @scale-rate-360;
          font-weight: 800 * @scale-rate-360;
        }
      }

      .li-text {
        padding: 0 36px;
        margin-top: 31px;
        font-size: 14px;
        line-height: 25px;
        height: 100px;
        color: #90A0AE;
        text-align: left;

        @media @max-768 {
          padding: 0 36px * @scale-rate-768;
          margin-top: 31px * @scale-rate-768;
          font-size: 14px * @scale-rate-768;
          line-height: 25px * @scale-rate-768;
        }

        @media @max-428 {
          padding: 0 36px * @scale-rate-428;
          margin-top: 31px * @scale-rate-428;
          font-size: 14px * @scale-rate-428;
          line-height: 25px * @scale-rate-428;
        }

        @media @max-360 {
          padding: 0 36px * @scale-rate-360;
          margin-top: 31px * @scale-rate-360;
          font-size: 14px * @scale-rate-360;
          line-height: 25px * @scale-rate-360;
        }
      }
    }
  }
}

// 产品特色
#feature {
  .v-column();
  width: 100%;
  background: #F6F7F9;
  justify-content: center;
  align-items: center;

  .title {
    margin-top: 58px;
    margin-bottom: 21px;

    @media @max-768 {
      margin-top: 58px * @scale-rate-768;
      margin-bottom: 21px * @scale-rate-768;
    }

    @media @max-428 {
      margin-top: 58px * @scale-rate-428;
      margin-bottom: 21px * @scale-rate-428;
    }

    @media @max-360 {
      margin-top: 58px * @scale-rate-360;
      margin-bottom: 21px * @scale-rate-360;
    }
  }

  ul {
    .v-row();
    //background-color: #e64340;
    max-width: 1440px;
    padding: 0 0 47px 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    li {
      .v-row();

      @width: 571px;
      @height: 240px;

      width: @width;
      min-height: @height;
      margin: 47px 27px 0 27px;
      background: #FFFFFF;
      box-shadow: 1px -1px 16px 0px #E6E9EE;
      border-radius: 3px;

      @media @max-768 {
        width: @width * 0.55;
        min-height: @height * 0.55;
      }

      @media @max-428 {
        width: @width * 0.5;
        min-height: @height * 0.5;
      }

      // Android 360px
      @media @max-360 {
        width: @width * 0.5;
        min-height: @height * 0.5;
      }

      &:hover{
        //box-shadow: 1px -1px 14px 0px #1492ff;
        //box-shadow: 2px 4px 6px #1492ff;
        //position: relative;
        //left: 30px;
        //top: -10px;
        box-shadow: 1px -1px 14px 0px #1492ff;
        transition: all ease .5s;

        //transform:scale(1.05, 1.05);
        //-ms-transform:scale(1.05, 1.05);
        //-webkit-transform:scale(1.05, 1.05);

      }

      .left {
        flex: 1;
        margin-left: 40px;
        margin-top: 38px;
        margin-bottom: 38px;

        @media @max-768 {
          margin-left: 40px * 0.55;
          margin-top: 38px * 0.55;
          margin-bottom: 38px * 0.55;
        }

        @media @max-428 {
          margin-left: 40px * 0.5;
          margin-top: 38px * 0.5;
          margin-bottom: 38px * 0.5;
        }


        // Android 360px
        @media @max-360 {
          margin-left: 40px * 0.5;
          margin-top: 38px * 0.5;
          margin-bottom: 38px * 0.5;
        }

        .li-title {
          font-size: 21px;
          font-weight: bold;
          color: #0B2D4B;
          text-align: left;

          @media @max-768 {
            font-size: 21px * 0.55;
          }

          @media @max-428 {
            font-size: 21px * 0.5;
          }

          // Android 360px
          @media @max-360 {
            font-size: 21px * 0.5;
          }
        }

        .li-text {
          white-space: pre-wrap;
          font-size: 14px;
          line-height: 30px;
          color: #828282;
          text-align: left;
          margin-top: 16px;

          @media @max-768 {
            font-size: 21px * 0.55;
            line-height: 30px * 0.55;
          }

          @media @max-428 {
            font-size: 14px * 0.5;
            line-height: 30px * 0.5;
          }

          // Android 360px
          @media @max-360 {
            font-size: 14px * 0.5;
            line-height: 30px * 0.5;
          }
        }
      }

      .right {
        .v-column();
        justify-content: flex-end;
        align-items: flex-end;
        .li-icon {
          margin: 27px 20px;
          width: 125px;
          height: 113px;

          @media @max-768 {
            margin: 27px * 0.55 20px * 0.55;
            width: 125px * 0.55;
            height: 113px * 0.55;
          }

          @media @max-428 {
            margin: 27px * 0.5 20px * 0.5;
            width: 125px * 0.5;
            height: 113px * 0.5;
          }

          // Android 360px
          @media @max-360 {
            margin: 27px * 0.5 20px * 0.5;
            width: 125px * 0.5;
            height: 113px * 0.5;
          }
        }
      }
    }
  }
}

// 部署方式
#deploy {
  .v-column();
  width: 100%;
  background: white;
  justify-content: center;
  align-items: center;

  .title {
    margin-top: 55px;
    margin-bottom: 21px;

    @media @max-768 {
      margin-top: 55px * @scale-rate-768;
      margin-bottom: 21px * @scale-rate-768;
    }

    @media @max-428 {
      margin-top: 55px * @scale-rate-428;
      margin-bottom: 21px * @scale-rate-428;
    }

    @media @max-360 {
      margin-top: 55px * @scale-rate-360;
      margin-bottom: 21px * @scale-rate-360;
    }
  }

  ul {
    .v-row();
    //background-color: #e64340;
    max-width: 1440px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media @max-768 {
      margin-top: 55px * @scale-rate-768;
      margin-bottom: 21px * @scale-rate-768;
      flex-wrap: wrap;
    }

    @media @max-428 {
      margin-top: 55px * @scale-rate-428;
      margin-bottom: 21px * @scale-rate-428;
      flex-wrap: wrap;
    }

    @media @max-360 {
      margin-top: 55px * @scale-rate-360;
      margin-bottom: 21px * @scale-rate-360;
      flex-wrap: wrap;
    }

    li {
      .v-column();

      @width: 342px;
      @height: 47px + 74px + 64px + 68px;

      width: @width;
      min-height: @height;
      margin: 48px 50px 0 50px;
      background: #FFFFFF;
      align-items: center;

      @media @max-768 {
        width: @width * 0.8;
        min-height: @height * 0.55;
        margin: 48px * 0.55 50px * 0.55 0 50px * 0.55;
      }

      @media @max-428 {
        width: @width * 0.7;
        min-height: @height * 0.5;
        margin: 48px * 0.5 50px * 0.5 0 50px * 0.5;
      }

      // Android 360px
      @media @max-360 {
        width: @width * 0.7;
        min-height: @height * 0.5;
        margin: 48px * 0.5 50px * 0.5 0 50px * 0.5;
      }

      .li-icon {
        @width: 67px;
        @height: 47px;
        width: @width;
        height: @height;

        @media @max-768 {
          width: @width * @scale-rate-768;
          height: @height * @scale-rate-768;
        }

        @media @max-428 {
          width: @width * @scale-rate-428;
          height: @height * @scale-rate-428;
        }

        @media @max-360 {
          width: @width * @scale-rate-360;
          height: @height * @scale-rate-360;
        }
      }

      .li-title {
        @top: 24px;
        @font-size: 21px;
        margin-top: @top;
        font-size: @font-size;
        font-weight: bold;
        color: #0B2D4B;

        @media @max-768 {
          margin-top: @top * @scale-rate-768;
          font-size: @font-size * @scale-rate-768;
        }

        @media @max-428 {
          margin-top: @top * @scale-rate-428;
          font-size: @font-size * @scale-rate-428;
        }

        @media @max-360 {
          margin-top: @top * @scale-rate-360;
          font-size: @font-size * @scale-rate-360;
        }
      }

      .li-text {
        margin-top: 31px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: #0B2D4B;
        text-align: left;
        //background-color: #e64340;

        @media @max-768 {
          margin-top: 31px * @scale-rate-768;
          font-size: 14px * @scale-rate-768;
          line-height: 25px * @scale-rate-768;
          font-weight: 400 * @scale-rate-768;
        }

        @media @max-428 {
          margin-top: 31px * @scale-rate-428;
          font-size: 14px * @scale-rate-428;
          line-height: 25px * @scale-rate-428;
          font-weight: 400 * @scale-rate-428;
        }

        @media @max-360 {
          margin-top: 31px * @scale-rate-360;
          font-size: 14px * @scale-rate-360;
          line-height: 25px * @scale-rate-360;
          font-weight: 400 * @scale-rate-360;
        }
      }
    }
  }
}

#contact {
  .v-column();
  width: 100%;
  background-color: #2394FB;
  justify-content: center;
  align-items: center;

  ul {
    .v-row();
    //background-color: #e64340;
    //max-width: 1440px;
    width: 100%;
    //background-color: #e64340;
    padding: 30px 20px 10px 20px;
    justify-content: center;
    align-items: center;

    @media @max-768 {
      padding: 30px * @scale-rate-768 20px * @scale-rate-768 10px * @scale-rate-768 20px * @scale-rate-768;
    }

    @media @max-428 {
      //padding: 30px 20px 10px 20px;
      padding: 30px * @scale-rate-428 20px * @scale-rate-428 10px * @scale-rate-428 20px * @scale-rate-428;
    }

    // Android 360px
    @media @max-360 {
      //padding: 30px 20px 10px 20px;
      padding: 30px * @scale-rate-360 20px * @scale-rate-360 10px * @scale-rate-360 20px * @scale-rate-360;
    }

    li {
      .v-column();

      @width: 300px;
      flex: 1;
      @height: 56px + 17px + 20px;
      max-width: @width;
      min-height: @height;
      padding: 40px 0px;
      align-items: center;
      //background-color: #5a6875;

      @media @max-768 {
        max-width: @width * @scale-rate-768;
        min-height: @height * @scale-rate-768;
        padding: 40px * @scale-rate-768 0px * @scale-rate-768;
      }

      @media @max-428 {
        max-width: @width * @scale-rate-428;
        min-height: @height * @scale-rate-428;
        padding: 40px * @scale-rate-428 0px * @scale-rate-428;
      }

      // Android 360px
      @media @max-360 {
        max-width: @width * @scale-rate-360;
        min-height: @height * @scale-rate-360;
        padding: 40px * @scale-rate-360 0px * @scale-rate-360;
      }

      .li-icon {
        width: 52px;
        height: 56px;

        @media @max-768 {
          width: 52px * @scale-rate-768;
          height: 56px * @scale-rate-768;
        }

        @media @max-428 {
          width: 52px * @scale-rate-428;
          height: 56px * @scale-rate-428;
        }

        @media @max-360 {
          width: 52px * @scale-rate-360;
          height: 56px * @scale-rate-360;
        }
      }

      .li-title {
        margin-top: 16px;
        font-size: 20px;
        font-weight: 800;
        color: #FFFFFF;

        @media @max-768 {
          margin-top: 16px * @scale-rate-768;
          font-size: 20px * @scale-rate-768;
          font-weight: 800 * @scale-rate-768;
        }

        @media @max-428 {
          margin-top: 16px * @scale-rate-428;
          font-size: 20px * @scale-rate-428;
          font-weight: 800 * @scale-rate-428;
        }

        @media @max-360 {
          margin-top: 16px * @scale-rate-360;
          font-size: 20px * @scale-rate-360;
          font-weight: 800 * @scale-rate-360;
        }
      }
    }
  }

  .c-text {
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;

    @media @max-768 {
      font-size: 24px * @scale-rate-768;
    }

    @media @max-428 {
      font-size: 24px * @scale-rate-428;
    }

    @media @max-360 {
      font-size: 24px * @scale-rate-360;
    }
  }

  a {
    cursor: pointer;
  }

  .c-button {
    @top: 30px;
    @bottom: 42px;
    @width: 168px;
    @height: 51px;

    margin-top: @top;
    margin-bottom: @bottom;
    width: @width;
    height: @height;

    @media @max-768 {
      margin-top: @top * @scale-rate-768;
      margin-bottom: @bottom * @scale-rate-768;
      width: @width * @scale-rate-768;
      height: @height * @scale-rate-768;
    }

    @media @max-428 {
      margin-top: @top * @scale-rate-428;
      margin-bottom: @bottom * @scale-rate-428;
      width: @width * @scale-rate-428;
      height: @height * @scale-rate-428;
    }

    @media @max-360 {
      margin-top: @top * @scale-rate-360;
      margin-bottom: @bottom * @scale-rate-360;
      width: @width * @scale-rate-360;
      height: @height * @scale-rate-360;
    }
  }
}

</style>
