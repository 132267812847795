<template>
  <div id="footer">
    <div id="top">
      <ul>
        <li v-for="(item, index) in contacts" :key="index">{{ item }}</li>
      </ul>
    </div>
    <div id="bottom">
    <ul>
      <li v-for="(item, index) in infos" :key="index">{{ item }}</li>
    </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      contacts: ['中国 · 杭州 · 滨江区 · 云狐科技园', '邮箱：shimatech@163.com'],
      infos: ['杭州诗码科技有限公司', '版权所有  Copyright © 2020 All rights reserved', '浙ICP备20021868号'],
    };
  }
}
</script>

<style scoped lang="less">
@import "../assets/css/base";
#footer {
  background-color: #011529;
  justify-content: center;
  align-items: center;
}

#top {

  ul {
    .v-row();
    flex-wrap: wrap;
    padding-top: 59px;
    justify-content: center;
    align-items: center;

    @media @max-768 {
      .v-column();
      padding-top: 59px * @scale-rate-768;
    }

    @media @max-428 {
      .v-column();
      padding-top: 59px * @scale-rate-428;
    }

    @media @max-360 {
      .v-column();
      padding-top: 59px * @scale-rate-360;
    }

    li {
      margin-top: 10px;
      padding: 0 20px;
      font-size: 19px;
      font-weight: 400;
      color: #5A6874;

      @media @max-768 {
        margin-top: 10px * @scale-rate-768;
        padding: 0px * @scale-rate-768 40px * @scale-rate-768;
        font-size: 19px * @scale-rate-768;
        font-weight: 400 * @scale-rate-768;
      }

      @media @max-428 {
        margin-top: 10px * @scale-rate-428;
        padding: 0px * @scale-rate-768 40px * @scale-rate-428;
        font-size: 19px * @scale-rate-428;
        font-weight: 400 * @scale-rate-428;
      }

      @media @max-360 {
        margin-top: 10px * @scale-rate-360;
        padding: 0px * @scale-rate-360 40px * @scale-rate-360;
        font-size: 19px * @scale-rate-360;
        font-weight: 400 * @scale-rate-360;
      }
    }
  }
}

#bottom {

  ul {
    .v-row();
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 23px;
    padding-bottom: 55px;

    @media @max-768 {
      .v-column();
      padding-top: 23px * @scale-rate-768;
      padding-bottom: 65px * @scale-rate-768;
    }

    @media @max-428 {
      .v-column();
      padding-top: 23px * @scale-rate-428;
      padding-bottom: 65px * @scale-rate-428;
    }

    @media @max-360 {
      .v-column;
      padding-top: 23px * @scale-rate-360;
      padding-bottom: 65px * @scale-rate-360;
    }


    li {
      padding: 0px 14px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #5A6874;

      @media @max-768 {
        padding: 0px * @scale-rate-768 14px * @scale-rate-768;
        margin-bottom: 10px * @scale-rate-768;
        font-size: 14px * @scale-rate-768;
        font-weight: 400 * @scale-rate-768;
      }

      @media @max-428 {
        padding: 0px * @scale-rate-768 14px * @scale-rate-428;
        margin-bottom: 10px * @scale-rate-428;
        font-size: 14px * @scale-rate-428;
        font-weight: 400 * @scale-rate-428;
      }

      @media @max-360 {
        padding: 0px * @scale-rate-360 14px * @scale-rate-360;
        margin-bottom: 10px * @scale-rate-360;
        font-size: 14px * @scale-rate-360;
        font-weight: 400 * @scale-rate-360;
      }
    }
  }
}
</style>
